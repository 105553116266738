import phcPdf from '@/services/utils/pdf/phcPdf';
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';

const phcPdfHelper = () => {
    const { exportToPDF } = phcPdf();
    const { fetchBarcode } = handleBarcode();
    const { formatDate } = useDate();
    const store = useStore();
    const userName = store.state.user.user.name;
    var barcode = '', qrcode = '', barcodePatient;

    const generatePdf = (company, data, patientId) => {
        let qrcodeData = JSON.stringify({
            ref_no: data.sale_master.bill_number,
            ref_date: data.start_date_time,
            created_by: userName,
            created_date_time: formatDate(new Date())
        })

        var barCodeQuery = `?code=${data.sale_master.bill_number}&qrcode=${qrcodeData}`;
        var barCodePatientQuery = `?code=${patientId}`;

        Promise.all([
            fetchBarcode(barCodeQuery).then( (res) => {
                barcode = res.barcode;
                qrcode = res.qrcode;
            }),
            fetchBarcode(barCodePatientQuery).then( (res) => {
                barcodePatient = res.barcode;
            })
        ]).then(() => {
            exportToPDF(company, data, barcode, qrcode, barcodePatient, patientId, userName)
        }).catch((e) => {
            console.log(e);
        })
    }

    return{
        generatePdf
    }
}

export default phcPdfHelper;