import Network from '@/services/network'

export default function handleHospitalPHC() {
    const network = new Network;

    const fetchSinglePHC = (query) => {
        return network.api('get', '/hospital/phc-registers' + query);
    }

    const fetchBloodPressure = (query) => {
        return network.api('get', '/hospital/phc-registers/blood-pressure-group-types' + query);
    }

    const storeOrUpdatePHC = (data) => {
        return network.api('post', '/hospital/phc-registers', data);
    }

    return {
        fetchSinglePHC,
        fetchBloodPressure,
        storeOrUpdatePHC
    }
}
